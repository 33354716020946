
import '../styles/results.css';
import { useState, useContext } from 'react';
import { AuthContext } from '../contexts/Contexts';
import { useEffect } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
const domain = "groots.krktechworks.com";

const fetchBanner = async () => {
  const response = await fetch('https://' + domain + '/elections/ap24/results/banner' );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchData_tdp = async () => {
  const response = await fetch('https://' + domain + '/elections/ap24/results/tdp' );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchData_jsp = async () => {
  const response = await fetch('https://' + domain + '/elections/ap24/results/jsp' );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchData_ycp = async () => {
  const response = await fetch('https://' + domain + '/elections/ap24/results/ycp' );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

function WhyNot175() {
  const {isLoggedIn, login, logout} = useContext(AuthContext);

  // Use useQuery for the first API call
  const { data: tdp_data, isLoading: isLoadingData1, error: errorData1 } = useQuery('tdp_data', fetchData_tdp);

  // Use useQuery for the second API call
  const { data: jsp_data, isLoading: isLoadingData2, error: errorData2 } = useQuery('jsp_data', fetchData_jsp);

  // Use useQuery for the third API call
  const { data: ycp_data, isLoading: isLoadingData3, error: errorData3 } = useQuery('ycp_data', fetchData_ycp);

  // Use useQuery for the fourth API call
  const { data: banner_data, isLoading: isLoadingData4, error: errorData4 } = useQuery('banner_data', fetchBanner);

  if (isLoadingData1 || isLoadingData2) {
    return <p>Loading...</p>;
  }

  if (errorData1 || errorData2) {
    return <p>Error fetching data</p>;
  }

  const results = [tdp_data.body, jsp_data.body, ycp_data.body];

  return (
    <div>
        <div className="banner">
            <p style={{color: 'red'}}>" <b> {banner_data.body.msg} </b> "</p>
        </div>
        <div className="Results">
            {results.map((result) => (
                <div className="Candidate">
                    <img src={result.img} alt={result.partyName} />
                    <p className="Won"><b>{result.won}</b></p>
                    <p className="Leading">(+{result.leading})</p>
                </div>
            ))}
        </div>
    </div>
  );
}

export default WhyNot175;