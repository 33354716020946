import { Outlet, NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import '../styles/navigation.css';
import Container from '@mui/material/Container';

const Layout = () => {
  return (
    <>
        <header>
            <ResponsiveAppBar/>
        </header>
        <Outlet />
    </>
  )
};

export default Layout;