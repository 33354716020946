import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatBot = () => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMessage = async () => {
    // Assuming you have a backend API for the chatbot
    const your_chatbot_api_url = 'https://groots.krktechworks.com/chatbot';
    const response = await fetch(your_chatbot_api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: input }),
    });

    const data = await response.json();

    setMessages([...messages, { text: input, sender: 'user' }]);
    setMessages([...messages, { text: data.response, sender: 'bot' }]);
    setInput('');
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Open Chatbot
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Chatbot</DialogTitle>
        <DialogContent>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {messages.map((msg, index) => (
              <div key={index} style={{ textAlign: msg.sender === 'bot' ? 'left' : 'right' }}>
                <strong>{msg.sender === 'bot' ? 'Bot:' : 'You:'}</strong> {msg.text}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Type a message"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <IconButton color="primary" onClick={handleSendMessage}>
              <SendIcon />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChatBot;
