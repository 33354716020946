// Home.js
import React from 'react';
import ChatBotPopup from '../components/ChatBotPopup';
import ChatBot from '../components/ChatBot';
import {Button, Box, ImageList, ImageListItem, ImageListItemBar} from '@mui/material'
import Footer from '../components/Footer';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


const Home = () => {
  return (
    <Box sx={{
        height: '90vh', // Set the height to 100% of the viewport height
        backgroundColor: 'yellow', // Example background color
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
      }}>
        <Box display='flex' sx={{width: '100%', height: '40%', alignItems: 'center', backgroundColor: '#EAECEE', justifyContent: 'center'}}>
            <Button variant="contained">Hello1</Button>
            <Button variant="contained">Hello2</Button>
            <Button variant="contained">Hello3</Button>
            <Button variant="contained">Hello4</Button>
            <ChatBot />
        </Box>
        <Box display='flex' sx={{width: '100%', height: '60%', alignItems: 'center', backgroundColor: 'white', justifyContent: 'center'}}>
            <Grid container spacing={2}>
              <Grid xs={8}>
                <Button variant='contained'>xs=8</Button>
              </Grid>
              <Grid xs={4}>
                <Button variant='contained'>xs=4</Button>
              </Grid>
              <Grid xs={4}>
                <Button variant='contained'>xs=4</Button>
              </Grid>
              <Grid xs={8}>
                <Button variant='contained'>xs=8</Button>
              </Grid>
            </Grid>
        </Box>
    </Box>
  );
};

export default Home;


const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
];