import { AuthContext } from './Contexts';
import { useState, useContext } from 'react';

export default function AuthContextProvider({children}) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login =  () => {
    setIsLoggedIn(true);
  }

  const logout =  () => {
    setIsLoggedIn(false);
  }

  return (
      <AuthContext.Provider value={{isLoggedIn, login, logout}}>
        {children}
      </AuthContext.Provider>
  );
}
