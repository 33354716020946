import React from 'react';
import './About.css'; // Import your CSS file for styling

const About = () => {
  return (
    <div className="about-page">
      <div className="about-content">
        <h1>About Me</h1>
        <p>
          Welcome to my personal website! I'm [Your Name], a passionate [Your Profession] based in [Your Location].
          [Add a brief and engaging introduction about yourself, your skills, and your interests.]
        </p>
        <p>
          I have a strong background in [Your Expertise], and I love [Something You Love].
          [Share a bit about your professional journey, experiences, and any notable achievements.]
        </p>
        <p>
          When I'm not coding or working on exciting projects, you can find me [Your Hobbies or Interests].
          [Share some personal aspects to connect with your audience.]
        </p>
        <h2>Skills</h2>
        <ul>
          <li>JavaScript (React, Node.js)</li>
          <li>HTML5, CSS3 (Sass, Styled Components)</li>
          <li>[Your Additional Skills]</li>
        </ul>
        <h2>Contact</h2>
        <p>
          Feel free to get in touch with me! You can reach me at [Your Email Address] or connect with me on [Your Social Media].
        </p>
      </div>
    </div>
  );
};

export default About;
