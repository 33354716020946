import { useState, useContext } from 'react';
import { AuthContext } from '../contexts/Contexts';

const OAuthCallback = () => {
    const {isLoggedIn, login, logout} = useContext(AuthContext);
    // Extract the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    // Get ID token
    fetch('https://groots.krktechworks.com/oauth2bk/cb?code=' + code)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Accessing a response header 'Authorization'
            const Authorization = response.headers.get('Authorization');
            console.log(`Authorization header: ${Authorization}`);

            // Parse the response body as JSON
            return Authorization;
        })
        .then(authorizationHeader => {
            login();
            console.log(authorizationHeader);
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });


    return (
        <div>
            <div>Callback... ha ha...</div>
            <div>code={code}</div>
            <div>LoggedIn={isLoggedIn.toString()}</div>
        </div>
    );
};

export default OAuthCallback;