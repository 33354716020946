import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {NavLink} from 'react-router-dom';

import '../styles/navigation.css';
import '../styles/results.css';

import {useState} from 'react';
import { useContext } from 'react';
import { AuthContext } from '../contexts/Contexts';

const pages = ['About', 'Contact', 'Blog', 'ElectionResults'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const {isLoggedIn, login, logout} = useContext(AuthContext);
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box className='navbar' sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <NavLink to='/about'>About</NavLink>
            <NavLink to='/blogs'>Blog</NavLink>
            <NavLink to='/contact'>Contact</NavLink>
            <NavLink to='/home'>Home</NavLink>
            <NavLink to='/gallery'>Gallery</NavLink>
            <NavLink to='/whynot175'>175</NavLink>
          </Box>
          <Box className='navbar' sx={{ml: 'auto'}}>
            { isLoggedIn ? <NavLink to='/logout'>Logout</NavLink> : <NavLink to='/login'>Login</NavLink> }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;