import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import {AppBar} from '@mui/material'
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);