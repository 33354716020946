// App.js
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import Login from "./pages/Login";
import Gallery from "./pages/Gallery";
import {AppBar} from '@mui/material';
import AuthContextProvider from './contexts/AuthContextProvider';
import WhyNot175 from './pages/WhyNot175';
import OAuthCallback from './pages/OAuthCallback';

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Gallery />} />
      <Route path="contact" element={<Contact />} />
      <Route path="about" element={<About />} />
      <Route path="home" element={<Home />} />
      <Route path="blogs" element={<Blogs />} />
      <Route path="login" element={<Login />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="whynot175" element={<WhyNot175 />} />
      <Route path="/oauth2/cb" element={<OAuthCallback />} />
    </Route>
  )
);

export default function App() {
  return (
    <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    </AuthContextProvider>
  );
}
