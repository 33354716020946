import { useState, useContext } from 'react';
import { AuthContext } from '../contexts/Contexts';
import { Button } from '@mui/material';

const Login = () => {
    return (
        <div>
            {signin()}
        </div>
    );
};

function signin() {
    const signInUrl = "https://signin.krktechworks.com/login?client_id=7kb157ep431qgbvl3kdk3mr3v4&response_type=code&scope=email+openid+profile&redirect_uri=https://groots.krktechworks.com/oauth2/cb";

    // Redirect the browser to the sign-in URL
    window.location.href = signInUrl;
}


export default Login;


